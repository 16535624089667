
import { client } from "../client";
import { getCookie, setCookie } from "../utils/tools";

import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
export default  defineComponent({
  name: "login-in",
  data: () => {
    return {
      svg: "",
      code: "",
      params: {
        username: "",
        password: "",
      },
    };
  },
  computed: {},
  created() {
    this.getCaptcha();
  },

  mounted() {
    console.log("Component mounted");
  },
  methods: {
    async getCaptcha() {
      // try {
        // let v = Math.random().toString().slice(4, 8);
        let ret = await client.callApi("pub/Captcha", {});
        if (ret.isSucc) {
          this.svg = ret.res.imageData;
        }
      // } catch (error) {
      //   console.log(error);
      // }
    },

    async checkCaptcha() {
      // try {
        let res = await client.callApi("pub/CheckCaptcha", {code:this.code});
        if (res.isSucc) {
          return true;
        } else {

          ElMessage({
            message: "验证码不正确",
            type: "warning",
          });
          return false;
        }
      // } catch (error) {
      //   console.log(error);
      // }

    },
    //校验
    validate() {
      if (this.params.username.length < 2) {
        ElMessage({
          message: "用户名不能少于2位",
          type: "warning",
        });
        return false;
      }
      if (this.params.password.length < 6) {
        ElMessage({
          message: "密码不能少于6位",
          type: "warning",
        });
        return false;
      }
      return true;
    },

    //登录
    async toLogin() {
      let val = this.validate();
      if (val) {
        let res = await this.checkCaptcha();
        if (!res) {
          return;
        }
        let ret = await client.callApi("pub/Login", {
          username: this.params.username,
          password: this.params.password,
        });
        if (ret.isSucc) {
          setCookie("username", ret.res.username);
          setCookie("token", ret.res.token);
          this.$router.push({ name: "home-index" });
          return;
        }
        ElMessage({message:ret.err.message,type:"warning"})
      }
    },
  },
});
